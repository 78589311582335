<script>
import { mapActions, mapState } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      descripcion: "",
      idCampaña: null,
      mostrarModal: false,
      nuevaDescripcion: "",
      campañaEliminar: null,
    };
  },
  computed: {
    ...mapState("autenticacion", ["campañas"]),
  },
  methods: {
    ...mapActions("autenticacion", ["guardarCampaña", "obtenerCampañas", "eliminarCampaña", "actualizarCampaña", ]),
    async agregarCampaña() {
      if (!this.descripcion.trim()) {
        console.error("El nombre de la campaña no puede estar vacía");
        return;
      }
      try {
        const objetoDataCampaña = { descripcionActivacion: this.descripcion };
        await this.guardarCampaña(objetoDataCampaña);
        this.descripcion = "";
        await this.obtenerCampañas();
        toast.success(" ¡La campaña se ha agregado! ", {
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored"
        });
      } catch (error) {
        console.error("Error al guardar la campaña:", error);
      }
    },

    async mostrarModalEliminar(campaña) {
      const result = await Swal.fire({
        title: "Eliminar Campaña",
        text: "Esto puede afectar a apartados de reportes de tarjetas club y folios de QR.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await this.borrarCampaña(campaña)
      }
    },
    async borrarCampaña(campaña) {
      try {
        await this.eliminarCampaña(campaña.id);
        this.mostrarModal = false;
        await this.obtenerCampañas();
        toast.success(" ¡La campaña se ha eliminado! ", {
            hideProgressBar: true,
            autoClose: 2000,
            theme: "colored",
        });
      } catch (error) {
        console.error("Error al eliminar la campaña:", error);
      }
    },

    async mostrarModalActualizar(campaña) {
      if (campaña && campaña.id) {
        this.idCampaña = campaña.id;
        this.nuevaDescripcion = campaña.descripcionActivacion;
        this.mostrarModal = true;
      } else {
        console.error("La campaña no está definida o no tiene un ID");
      }
    },
    async modificarCampaña() {
      try {
        if (!this.idCampaña) {
          console.error(
            "No se ha seleccionado ninguna campaña para actualizar"
          );
          return;
        }
        await this.actualizarCampaña({
          idCampaña: this.idCampaña,
          nuevaDescripcion: this.nuevaDescripcion,
        });
        this.mostrarModal = false;
        await this.obtenerCampañas();
        toast.success(" ¡La campaña se ha actualizado! ", {
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        });
      } catch (error) {
        console.error("Error al actualizar nombre de la campaña", error);
      }
    },
  },

  mounted() {
    this.obtenerCampañas();
  },
};
</script>

<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Alta de nueva campaña</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form class="theme-form" @submit.prevent="agregarCampaña">
        <div class="card-body">
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              name="descripcion"
              placeholder="Nombre de la campaña"
              v-model.trim="descripcion"
            />
          </div>
          <div class="form-group mb-0">
            <button
              class="btn btn-primary btn-sm btn-block w-25 mt-3"
              type="submit"
            >
              Agregar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="mt-2">
    <div class="container d-flex justify-content-center">
      <div class="card w-100 px-1" style="max-width: 900px">
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="fw-bold">Nombre de la Campaña</th>
                <th class="fw-bold">Fecha de creación</th>
                <th class="fw-bold">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in campañas" :key="item.id">
                <td>{{ item.descripcionActivacion }}</td>
                <td>{{ item.fecha }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger me-2"
                    @click="mostrarModalEliminar(item)"
                  >
                    <span class="btn-label"><i class="fa fa-trash"></i></span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    :data-bs-target="'#updateModal-' + idCampaña"
                    @click="mostrarModalActualizar(item)"
                  >
                    <span class="btn-label"><i class="fa fa-edit"></i></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    :id="'updateModal-' + idCampaña"
    tabindex="-1"
    aria-labelledby="updateModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h4 class="modal-title mx-auto" id="updateModalLabel">
            Actualizar Campaña
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <input
            v-model="nuevaDescripcion"
            type="text"
            class="form-control"
            placeholder="Nombre de la campaña"
          />
        </div>
        <div class="modal-footer justify-content-center border-0">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="modificarCampaña()"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
