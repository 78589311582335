<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import TablaLista from "../../components/tablaLista.vue";
import { arraySucursales } from "@/constants/sucursales";
export default {
  data() {
    return {
      fechaFiltradoPedidos: [],
      division: "",
      sucursal: "",
      listaSucursalesSk: [
      ...arraySucursales.sucursalesSk,
      ...arraySucursales.sucursalesSkMicro,
        // { nombre: "SK LAS MARINAS", division: 7104 },
        // { nombre: "SK LERMA", division: 7105 },
        // { nombre: "SK TOLLOCAN", division: 7120 },
        // { nombre: "SK PINO SUAREZ", division: 7121 },
        // { nombre: "SK TENANCINGO", division: 7122 },
        // { nombre: "SK SAN BUENA VENTURA", division: 7125 },
        // { nombre: "SK CENTRO", division: 7126 },
        // { nombre: "SK LA ASUNCION", division: 7127 },
        // { nombre: "SK SANTIAGO TIANGUISTENCO", division: 7128 },
        // { nombre: "SK ATLACOMULCO", division: 7129 },
        // { nombre: "SK ZITACUARO", division: 7130 },
        // { nombre: "SK IXTLAHUACA", division: 7150 },
        // { nombre: "SKM ZITACUARO", division: 7305 },
        // { nombre: "SKM VALLE DE BRAVO AV TOLUCA", division: 7306 },
        // { nombre: "SKM SAN PEDRO", division: 7308 },
        // { nombre: "SKM SAN MATEO", division: 7309 },
        // { nombre: "SKM LEANDRO VALLE", division: 7319 },
        // { nombre: "SKM FIDEL VELAZQUEZ", division: 7323 },
        // { nombre: "SKM VALLE DE BRAVO", division: 7324 },
        // { nombre: "SKM XONACATLAN", division: 7331 },
        // { nombre: "SKM ALAMEDA", division: 7332 },
        // { nombre: "SKM GALERIAS", division: 7333 },
        // { nombre: "SKM METEPEC", division: 7334 },
        // { nombre: "SKM LERDO", division: 7335 },
        // { nombre: "SKM XINANTECATL", division: 7337 },
        // { nombre: "SKM SEMINARIO", division: 7338 },
        // { nombre: "SKM ATLACOMULCO", division: 7339 },
        // { nombre: "SKM SANTIAGO MILTEPEC", division: 7344 },
        // { nombre: "SKM TENANGO", division: 7346 },
        // { nombre: "SKM IXTLAHUACA", division: 7347 },
      ],
      listaSucursalesSurtitienda: [
        ...arraySucursales.sucursalesSurtitienda,
        // { division: 7511, nombre: "ST FIDEL VELAZQUEZ" },
        // { division: 7512, nombre: "ST TENANCINGO" },
        // { division: 7513, nombre: "ST CENTRAL DE ABASTOS D.F" },
        // { division: 7514, nombre: "ST CENTRAL DE ABASTOS TOLUCA" },
        // { division: 7515, nombre: "ST IXTLAHUACA" },
        // { division: 7517, nombre: "ST ZITACUARO" },
      ],
      listaSucursalesAcropolis: [
        ...arraySucursales.sucursalesAcropolis,
        // { division: 7702, nombre: "ACROPOLIS TOLUCA CENTRO" },
        // { division: 7703, nombre: "ACROPOLIS IXTLAHUACA" },
      ],
      sucursalesFiltradas: [],
      empleado: "",
      promotor: "",
      headersTabla: [
        "Tarjeta",
        "Id Cliente",
        "Nombre",
        "Apellido paterno",
        "Apellido materno",
        "Email",
        "Teléfono",
        "Fecha de nacimiento",
        "Código postal",
        "Sexo",
        "Recomendo",
        "Sucursal",
        "Dio alta",
        "Fecha alta",
        "Fecha verificación",
        "Pre registro",
        "Promotor",
        "Verificó email",
      ],
      campos: [
        { tipo: "texto", clave: "tarjeta" },
        { tipo: "texto", clave: "id_cli" },
        { tipo: "texto", clave: "nombre" },
        { tipo: "texto", clave: "apellidoM" },
        { tipo: "texto", clave: "apellidoP" },
        { tipo: "texto", clave: "email" },
        { tipo: "texto", clave: "telefono" },
        { tipo: "texto", clave: "fechanacim" },
        { tipo: "texto", clave: "cp" },
        { tipo: "texto", clave: "sexo" },
        { tipo: "texto", clave: "recomendo" },
        { tipo: "texto", clave: "division" },
        { tipo: "texto", clave: "dioalta" },
        { tipo: "texto", clave: "fechaaltat" },
        { tipo: "texto", clave: "fechaverif" },
        { tipo: "texto", clave: "preregistro" },
        { tipo: "texto", clave: "promotor" },
        { tipo: "texto", clave: "verificoEmail" },
      ],
      tarjetasIniciales: [],
      tarjetasConsultados: [],
      tarjetasFiltradas: [],
    };
  },
  components: {
    TablaLista,
  },
  created() {
    this.cargarFechasIniciales();
  },
  computed: {
    ...mapState("validacion", ["tarjetasVerificadas"]),
    ...mapState("autenticacion", ["usuarioActual"]),
    mostrarFiltradoSucursales() {
      return this.division != "";
    },
    mostrarFiltradoEmpleado() {
      return this.division != "";
    },
  },
  methods: {
    ...mapActions("validacion", ["consultarTarjetahabientesVerificados"]),
    filtrarTarjetas() {
      this.tarjetasFiltradas = this.tarjetasConsultados.filter((tarjeta) => {
        console.log(tarjeta);
        if (
          tarjeta?.division.toString() && this.division.toString().split('|').some(subcadena =>
            tarjeta.division.toString().includes(subcadena)) &&
          tarjeta?.division.toString().includes(this.sucursal.toString()) &&
          tarjeta?.preregistro.toString().toUpperCase().includes(this.empleado.toUpperCase()) &&
          tarjeta?.promotor.toString().toUpperCase().includes(this.promotor.toUpperCase())
        ) {
          return tarjeta;
        }

        if (this.division === "71|73") {
          this.sucursalesFiltradas = this.listaSucursalesSk;
          if (
            tarjeta?.division.toString() && this.division.toString().split('|').some(subcadena =>
            tarjeta.division.toString().includes(subcadena)) &&
          tarjeta?.division.toString().includes(this.sucursal.toString()) &&
          tarjeta?.preregistro.toString().toUpperCase().includes(this.empleado.toUpperCase()) &&
          tarjeta?.promotor.toString().toUpperCase().includes(this.promotor.toUpperCase())
        ) {
          return tarjeta;
        } 
        } else if (this.division === "75") {
          this.sucursalesFiltradas = this.listaSucursalesSurtitienda;
          if (
            tarjeta?.division.toString() && this.division.toString().split('|').some(subcadena =>
            tarjeta.division.toString().includes(subcadena)) &&
          tarjeta?.division.toString().includes(this.sucursal.toString()) &&
          tarjeta?.preregistro.toString().toUpperCase().includes(this.empleado.toUpperCase()) &&
          tarjeta?.promotor.toString().toUpperCase().includes(this.promotor.toUpperCase())
        ) {
          return tarjeta;
        } 
        } else if (this.division === "77") {
          this.sucursalesFiltradas = this.listaSucursalesAcropolis;
          if (
            tarjeta?.division.toString() && this.division.toString().split('|').some(subcadena =>
            tarjeta.division.toString().includes(subcadena)) &&
          tarjeta?.division.toString().includes(this.sucursal.toString()) &&
          tarjeta?.preregistro.toString().toUpperCase().includes(this.empleado.toUpperCase()) &&
          tarjeta?.promotor.toString().toUpperCase().includes(this.promotor.toUpperCase())
        ) {
          return tarjeta;
        } 
        } else {
          this.sucursalesFiltradas = [];
        }
      });

      this.tarjetasFiltradas = this.tarjetasFiltradas.sort((a, b) => {
        if (a.fechaverif < b.fechaverif) {
          return 1;
        } else if (a.fechaverif > b.fechaverif) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    // ...mapActions("comercio", ["consultarPedidos"]),
    cargarFechasIniciales() {
      const fechaActual = moment();
      const fechaActualSinFecha = fechaActual.format("YYYY-MM-DD");
      const fechaActualformato = moment(fechaActualSinFecha);
      const fechaActualTimestamp = fechaActualformato.format("x");
      const fecha7diasAnteriores =
        parseInt(fechaActualTimestamp) - 6 * 86400000;
      const fechaActualDia =
        parseInt(fechaActualTimestamp) + 23 * 3600000 + 59 * 60000;
      const fechaInicio = moment(fecha7diasAnteriores).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      const fechaFin = moment(fechaActualDia).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      this.fechaFiltradoPedidos = [fechaInicio, fechaFin];
      console.log(this.fechaFiltradoPedidos);
      // this.consultarPedidos(this.fechaFiltradoPedidos);
    },
    // async verDetallePedido(elemento) {
    //   let pedidoSeleccionado = this.pedidos[elemento.id];
    //   pedidoSeleccionado['id'] = elemento.id;
    //   localStorage.setItem('detallePedido',JSON.stringify(pedidoSeleccionado));
    //   this.$router.push(`/detalle-pedido/${elemento.id}`);
    //   // this.$swal({
    //   //   title: "¡Producto agregado con éxito!",
    //   //   text: `${elemento?.nombre}`,
    //   //   icon: "success",
    //   //   confirmButtonText: "Continuar",
    //   //   confirmButtonColor: "#10B981"
    //   // })
    // },


    descargarExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datos_Maestros');
      worksheet.addRow(this.headersTabla);
      this.tarjetasFiltradas.forEach((tarjeta) => {
        const row = [];
        this.campos.forEach((campo) => {
          row.push(tarjeta[campo.clave]);
        });
        worksheet.addRow(row);
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Historico de verificacion.xlsx');
      });
    },
  },
  watch: {
    tarjetasVerificadas(nuevasTarjetas) {
      console.log(nuevasTarjetas);
      console.log("nuevosPreregistros");
      this.tarjetasConsultados = [];

      if (nuevasTarjetas) {
        console.log("SI HAY");
        for (const [id, tarjeta] of Object.entries(nuevasTarjetas)) {
          this.tarjetasIniciales.push(tarjeta);
          // const fechaSegundos = pedido["TimeSolicitud"] / 1000;
          // const fechaFormato = moment
          //   .unix(fechaSegundos)
          //   .format("DD/MM/YYYY, h:mm A");
          const objetoPreregistro = {
            tarjeta: tarjeta["tarjeta"],
            id_cli: tarjeta["id_cli"],
            nombre: tarjeta["nombre"],
            apellidoP: tarjeta["apellidoP"],
            apellidoM: tarjeta["apellidoM"],
            email: tarjeta["email"],
            telefono: tarjeta["telefono"],
            fechanacim: tarjeta["fechanacim"],
            cp: tarjeta["cp"],
            sexo: tarjeta["sexo"],
            recomendo: tarjeta["recomendo"] ? tarjeta["recomendo"] : "-",
            division: tarjeta["division"],
            dioalta: tarjeta["dioalta"],
            fechaaltat: tarjeta["fechaaltat"],
            fechaverif: tarjeta["fechaverif"],
            preregistro: tarjeta["preregistro"] ? tarjeta["preregistro"] : "-",
            promotor: tarjeta["promotor"],
            verificoEmail: tarjeta["verificoEmail"],
          };
          this.tarjetasConsultados.push(objetoPreregistro);
        }
        console.log(this.tarjetasConsultados);
        this.tarjetasFiltradas = this.tarjetasConsultados;
      } else {
        console.log("NO HAY");
        this.tarjetasConsultados = [];
        this.tarjetasFiltradas = this.tarjetasConsultados;
      }
      this.filtrarTarjetas();
    },
    fechaFiltradoPedidos(fechas) {
      this.consultarTarjetahabientesVerificados(fechas);
    },
  },
};
</script>
<template>
  <Breadcrumbs title="Histórico" />

  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por Formato:</label>
            <select class="form-select" id="division" aria-describedby="division" v-model.trim="division"
              @change="filtrarTarjetas()">
              <option value="">General</option>
              <option value="71|73">Super Kompras</option>
              <option value="75">Surtitienda</option>
              <option value="77">Acropolis</option>
            </select>
          </div>
          <div class="col-lg-4 col-0"><label>Promotoria:</label>
            <select class="form-select" id="promotor" aria-describedby="promotor" v-model.trim="promotor"
              @change="filtrarTarjetas()">
              <option value="">Ambos</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por fecha:</label>
            <datepicker class="datepicker-here digits" range id="fechaPedidos" :enable-time-picker="false" type="date"
              locale="es" format="dd/MM/yyyy" select-text="Confirmar" cancel-text="Cancelar"
              v-model="fechaFiltradoPedidos" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <div v-if="mostrarFiltradoSucursales">
              <label>Filtrado por Sucursal:</label>
              <select class="form-select" id="sucursal" aria-describedby="sucursal" v-model.trim="sucursal"
                @change="filtrarTarjetas()">
                <option value="">Todos</option>
                <option v-for="sucursal in sucursalesFiltradas" :key="sucursal.division" :value="sucursal.division">{{
                  sucursal.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <div v-if="mostrarFiltradoEmpleado">
              <label>Filtrado por empleado:</label>
              <input class="form-control" id="empleado" type="text" aria-describedby="empleado" placeholder="Empleado"
                v-model.trim="empleado" @input="filtrarTarjetas()" />
            </div>
          </div>
          <div class="col-lg-4 col-12 mb-3 d-flex justify-content-center">
            <button type="button" class="btn btn-outline-success" @click="descargarExcel">
              Descargar Excel
            </button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <TablaLista :data="tarjetasFiltradas" :headers="headersTabla" :search="false" :campos="campos"
          :listaEstatus="listaEstatus" @entregarTarjeta="entregarTarjeta"></TablaLista>
      </div>
    </div>
  </div>
</template>
