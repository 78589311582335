<script>
import moment from "moment";
import BasicTable from "../../../components/basicTable.vue";
import FormularioDetallePedido from "../../../components/formularioDetallePedido.vue";
export default {
  data() {
    return {
      pedido: {},
      infoPedido: [],
      infoCliente: {},
      infoPago: {},
      productos: [],
      headersInfoPedido: [
        "Tipo de envio",
        "Fecha y hora",
        "Sucursal",
        "Estatus",
        "Key BBDD"
      ],
      camposInfoPedido: [
        { tipo: "texto", clave: "tipoEnvio", bold: true },
        { tipo: "texto", clave: "fechaHora" },
        { tipo: "texto", clave: "sucursal" },
        { tipo: "estatus", clave: "estatus" },
        { tipo: "texto", clave: "keyBBDD" },
      ],
      listaEstatusInfoPedido: [
        { estatus: "Solicitud", color: "#F97316" },
        { estatus: "En proceso", color: "#3B82F6" },
        { estatus: "Cancelado", color: "#E11D48" },
        { estatus: "Concluido", color: "#10B981" },
        { estatus: "En ruta", color: "#A21CAF" },
      ],
      headersArticulos: [
        "ID",
        "Imagen",
        "Nombre",
        "Cantidad Piezas o Gramaje",
        "Precio",
        "Comentarios",
        "Acciones",
      ],
      camposArticulos: [
        { tipo: "texto", clave: "id" },
        { tipo: "imagen", clave: "imagen" },
        { tipo: "texto", clave: "nombre" },
        { tipo: "texto", clave: "cantidad" },
        { tipo: "texto", clave: "precio" },
        { tipo: "texto", clave: "comentarios" },
        { tipo: "boton" },
      ],
    };
  },
  components: {
    BasicTable,
    FormularioDetallePedido,
  },
  created() {
    this.cargarPedido();
  },
  methods: {
    cargarPedido() {
      this.pedido = JSON.parse(localStorage.getItem("detallePedido"));

      const fechaSegundos = this.pedido["TimeSolicitud"] / 1000;
      const fechaFormato = moment
        .unix(fechaSegundos)
        .format("DD/MM/YYYY, h:mm A");

      this.infoPedido = [
        {
          tipoEnvio: this.pedido["recogerTienda"]
            ? "Recoger en tienda"
            : "Envío a domicilio",
          fechaHora: fechaFormato,
          sucursal: this.pedido["sucursal"],
          estatus: this.pedido["Status"],
          keyBBDD: this.pedido["id"],
        },
      ];

      this.infoCliente = {
        nombre: this.pedido["Cliente name"],
        clienteId: this.pedido["Cliente id"],
        telefono: this.pedido["Cliente telefono"],
        direccion: this.pedido["Direccion"],
        codigoSk: this.pedido["TarjetaClubCodigo"],
        comentarios: this.pedido["Comentarios"],
        fotografia: this.pedido?.TarjetaClub.fileName,
      };

      this.productos = [];
      if (this.pedido.Productos) {
        for (const [id, producto] of Object.entries(this.pedido.Productos)) {
          const precioRedondeado = Math.round(producto.Total * 100) / 100;

          const objetoProducto = {
            id: id,
            imagen: producto.Imagen,
            nombre: producto.Nombre,
            cantidad: producto.Cantidad,
            precio: `$${precioRedondeado}`,
            comentarios: producto.Comentarios ? producto.Comentarios : "-",
          };
          this.productos.push(objetoProducto);
        }
      }

      this.infoPago = {
        subtotal: this.pedido.Subtotal ? (Math.round(this.pedido.Subtotal * 100) / 100) : 0,
        envio: this.pedido.Envio ? (Math.round(this.pedido.Envio * 100) / 100) : 0,
        total: this.pedido.Total ? (Math.round(this.pedido.Total * 100) / 100) : 0,
      }
    },
  },
};
</script>
<template>
  <Breadcrumbs :main="'Pedidos'" :title="pedido.Folio" />
  <BasicTable
    :headers="headersInfoPedido"
    :data="infoPedido"
    :campos="camposInfoPedido"
    :listaEstatus="listaEstatusInfoPedido"
    @verDetalleElemento="verDetallePedido"
  ></BasicTable>
  <FormularioDetallePedido :infoCliente="infoCliente"></FormularioDetallePedido>
  <BasicTable
    :headers="headersArticulos"
    :data="productos"
    :campos="camposArticulos"
    title="Artículos"
    @verDetalleElemento="verDetallePedido"
    total = "true"
    titleAmount="artículos"
  ></BasicTable>
  <div class="card">
    <div class="card-header pb-0">
      <h4>Detalle de pago</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <div class="row mb-2">
            <div class="col-6">
              <h6>Subtotal</h6>
            </div>
            <div class="col-6">${{ infoPago.subtotal }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <h6>Envio</h6>
            </div>
            <div class="col-6">${{ infoPago.envio }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <h6>Total</h6>
            </div>
            <div class="col-6">${{ infoPago.total }}</div>
          </div>
        </div>
        <div class="col-8">
          <span class="fw-bold pe-4">Método de pago</span>
          <span class="d-inline-block text-capitalize">
            <div class="d-flex align-items-center">
              {{ pedido.Pago }}
              <i
                v-if="pedido.Pago == 'efectivo'"
                class="ms-3 icofont icofont-2x icofont-bill"
                style="color: #13cc13;"
              ></i>
              <i
                v-if="pedido.Pago == 'terminal'"
                class="ms-3 icofont icofont-2x icofont-bank-transfer-alt"
                style="color: grey;"
              ></i>
              <i
                v-if="pedido.Pago == 'visa'"
                class="ms-3 icofont icofont-2x icofont-visa"
                style="color: #4c31e6;"
              ></i>
              <i
                v-if="pedido.Pago == 'mastercard'"
                class="ms-3 icofont icofont-2x icofont-mastercard"
                style="color: #e42626;"
              ></i>

            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
