<script>

import moment from "moment";
import { mapActions } from "vuex";
export default {
  props: ["urlSiguiente"],
  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      fechaNacimiento: "",
      tarjeta: "",
      botonDesactivado: false,
    };
  },
  created() {
    
  },
  methods: {
    ...mapActions("validacion", [
      "guardarDatosValidadosTarjetahabiente",
      "consultarConteoCli",
      "buscarTarjetahabienteFirebase",
      "consultarTarjetahabienteHasura",
      "actualizarDatosTarjetahabienteHasura",
      "guardarDatosTarjetahabienteHasura",
      "buscarTarjeta",
    ]),
    async validarFormularioVerificacion(){
      this.botonDesactivado = true;
      const errores = [];
      const patronFecha = /^\d{2}\/\d{2}\/\d{4}$/;
      console.log(this.tarjeta);
      const prefijoTarjeta = this.tarjeta.toString().substring(0, 2);
      console.log(prefijoTarjeta);

      if (this.tarjeta == "") {
        errores.push({ msg: "Proporcione una tarjeta" });
      }

      if (
        this.telefono != "" &&
        (isNaN(this.telefono.toString()) ||
          this.telefono.toString().length != 10)
      ) {
        errores.push({ msg: "Teléfono inválido" });
      }
      if (
        this.fechaNacimiento != "" &&
        !patronFecha.test(this.fechaNacimiento)
      ) {
        errores.push({ msg: "Introduce una fecha valida" });
      }
      if (
        this.fechaNacimiento != "" &&
        !this.revisarMayoriaEdad(this.formatoFechaNacimiento())
      ) {
        errores.push({ msg: "Para continuar necesitas ser mayor de edad" });
      }
      if (
        isNaN(this.tarjeta.toString()) ||
        this.tarjeta.toString().length != 13
      ) {
        errores.push({ msg: "Número de tarjeta inválido" });
      }

      if (prefijoTarjeta != "60" && prefijoTarjeta != "80") {
        errores.push({
          msg: "Número de tarjeta no corresponde a Super Kompras",
        });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.botonDesactivado = false;
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
      } else {
        const tarjetaEncontrada = await this.buscarTarjetaRegalo(this.tarjeta.toString())
        console.log(tarjetaEncontrada);
        if (tarjetaEncontrada) {
          this.$swal({
            icon: "warning",
            type: "warning",
            title: "Oops...",
            text: "Esta tarjeta de regalo ya fue dada de alta",
          });
          this.botonDesactivado = false;
        } else {
          this.guardarTarjetaRegalo();
        }
        // this.guardarTarjetaRegalo();
      }
    },
    async buscarTarjetaRegalo(tarjeta) {
      try {
        const tarjetaEncontrada = await this.buscarTarjeta(tarjeta);
        return tarjetaEncontrada;
      } catch (error) {
        console.log("Hola");
        return null;
      }
    },
    async guardarTarjetaRegalo() {
      try {
        const id_cli = (await this.consultarConteoCli()) + 1;

        const fechaActual = moment();
        const formatoFechaActual = fechaActual.format("YYYY-MM-DD");
        console.log("Fecha formato: " + formatoFechaActual);

        const dataTarjetahabiente = {
          apellidoM: this.apellidoMaterno ? this.apellidoMaterno : "null",
          apellidoP: this.apellidoPaterno ? this.apellidoPaterno : "null",
          dioalta: "null",
          email: "appsk@tiendasgarces.com",
          fechanacim: this.fechaNacimiento
            ? this.formatoFechaNacimiento()
            : "1900-01-01",
          nombre: this.nombre ? this.nombre : "null",
          saldoc: 0,
          sexo: "null",
          tarjeta: this.tarjeta.toString(),
          telefono: this.telefono ? this.telefono : 0,
          verificoEmail: "null",
          cp: 0,
          fechaaltat: formatoFechaActual,
          promotor: "null",
          division: 0,
          userID: "null",
          id_cli: id_cli,
        };

        console.log(dataTarjetahabiente);

        await this.guardarDatosValidadosTarjetahabiente(dataTarjetahabiente);

        await this.guardarTarjetahabienteHasura(this.tarjeta.toString());

        this.$swal({
          icon: "success",
          type: "success",
          title: "Datos actualizados correctamente!",
          allowOutsideClick: false,
          confirmButtonText: "Continuar",
        }).then((result) => {
          this.resetearCampos();
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally {
        this.botonDesactivado = false;
      }
    },

    async guardarTarjetahabienteHasura(id) {
      console.log("Guardando en HASURA");
      const dataTarjetahabiente = await this.buscarTarjetahabienteFirebase(id);

      console.log(dataTarjetahabiente);
      if (dataTarjetahabiente) {
        let id_cli = "";

        if (dataTarjetahabiente["id_cli"]) {
          id_cli = dataTarjetahabiente["id_cli"];
        } else {
          id_cli = (await this.consultarConteoCli()) + 1;
        }
        console.log(dataTarjetahabiente);
        const dataTarjetahabienteHasura = {
          Record_Id: dataTarjetahabiente["tarjeta"],
          apellidoM: dataTarjetahabiente["apellidoM"],
          apellidoP: dataTarjetahabiente["apellidoP"],
          dioalta: dataTarjetahabiente["dioalta"],
          email: dataTarjetahabiente["email"],
          fechanacim: dataTarjetahabiente["fechanacim"],
          nombre: dataTarjetahabiente["nombre"],
          saldoc: dataTarjetahabiente["saldoc"]
            ? parseFloat(dataTarjetahabiente["saldoc"])
            : 0.0,
          sexo: dataTarjetahabiente["sexo"],
          tarjeta: dataTarjetahabiente["tarjeta"],
          telefono: parseFloat(dataTarjetahabiente["telefono"]),
          verificoemail: dataTarjetahabiente["verificoEmail"]
            ? "true"
            : "false",
          cp: parseInt(dataTarjetahabiente["cp"]),
          fechaalta: dataTarjetahabiente["fechaaltat"],
          promotor: dataTarjetahabiente["promotor"] ? "true" : "false",
          division: parseInt(dataTarjetahabiente["division"]),
          userID: dataTarjetahabiente["userIDTimestamp"].slice(0, -13),
          fechaverfi: dataTarjetahabiente["fechaverif"],
          actcte: "true",
          id_cli: dataTarjetahabiente["id_cli"],
        };

        console.log("DATA TARJETAHABIENTE HASURA");
        console.log(dataTarjetahabienteHasura);

        const tarjetahabienteEncontrado =
          await this.consultarTarjetahabienteHasura(id);
        console.log("HOLA");
        console.log(tarjetahabienteEncontrado);

        if (tarjetahabienteEncontrado) {
          console.log("OP2");
          const responseActualizarHasura =
            await this.actualizarDatosTarjetahabienteHasura(
              dataTarjetahabienteHasura
            );

          console.log(responseActualizarHasura);
        } else {
          console.log("OP1");
          const responseGuardarHasura =
            await this.guardarDatosTarjetahabienteHasura(
              dataTarjetahabienteHasura
            );

          console.log(responseGuardarHasura);
        }
      }
    },

    cancelarVerificacion() {
      this.resetearCampos();
      
    },

    resetearCampos() {
      this.nombre = "";
      this.apellidoPaterno = "";
      this.apellidoMaterno = "";
      this.nombre = "";
      this.telefono = "";
      this.fechaNacimiento = "";
      this.tarjeta = "";
      this.sexo = "";
    },
    revisarMayoriaEdad(fecha) {
      const fechaActual = moment().format("YYYY-MM-DD");
      const fechaActualOffsetTimestamp =
        parseInt(moment(fechaActual).format("x")) + 39600000 + 3540000;
      const fechaRevisar = moment(fecha).format("x");
      console.log(fechaActualOffsetTimestamp);
      console.log(fechaRevisar);
      console.log(fechaActualOffsetTimestamp - fechaRevisar);
      return fechaActualOffsetTimestamp - fechaRevisar >= 568067623175;
    },
    formatoFechaNacimiento() {
      console.log(this.fechaNacimiento);
      const arrayFecha = this.fechaNacimiento.split("/");
      const fechaFormatoMoment =
        arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];

      console.log(fechaFormatoMoment);
      return fechaFormatoMoment;
    },
    darAutoFormatoFecha() {
      let fechaFormato = this.fechaNacimiento.replace(/\D/g, ""); // Elimina todos los caracteres no numéricos

      if (fechaFormato.length > 2) {
        fechaFormato =
          fechaFormato.substring(0, 2) + "/" + fechaFormato.substring(2);
      }
      if (fechaFormato.length > 5) {
        fechaFormato =
          fechaFormato.substring(0, 5) + "/" + fechaFormato.substring(5, 9);
      }

      this.fechaNacimiento = fechaFormato;
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Dar de alta Tarjeta de regalo</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="validarFormularioVerificacion()">
        <div class="card-body">
          <div class="mb-3">
            <label class="col-form-label pt-0" for="nombre">Nombre</label>
            <input
              class="form-control"
              id="nombre"
              type="text"
              aria-describedby="nombre"
              placeholder="Introduce tu nombre"
              v-model.trim="nombre"
            />
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoPaterno"
                  >Apellido paterno</label
                >
                <input
                  class="form-control"
                  id="apellidoPaterno"
                  type="text"
                  aria-describedby="apellidoPaterno"
                  placeholder="Introduce tu apellido paterno"
                  v-model.trim="apellidoPaterno"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoMaterno"
                  >Apellido materno</label
                >
                <input
                  class="form-control"
                  id="apellidoMaterno"
                  type="text"
                  aria-describedby="apellidoMaterno"
                  placeholder="Introduce tu apellido materno"
                  v-model.trim="apellidoMaterno"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="telefono">Teléfono</label>
            <div class="row">
              <div
                class="col-lg-1 col-2 d-flex align-items-center justify-content-lg-start justify-content-center"
                style="min-height: 100%"
              >
                <label class="col-form-label" for="prefijo">+52</label>
              </div>
              <div class="col-lg-11 col-10">
                <input
                  class="form-control"
                  id="telefono"
                  type="number"
                  aria-describedby="telefono"
                  placeholder="Introduce tu teléfono"
                  v-model.trim="telefono"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="col-form-label pt-0" for="fechaNacimiento"
                >Fecha de nacimiento</label
              >

              <input
                class="form-control"
                id="fechaNacimiento"
                type="tel"
                aria-describedby="fechaNacimiento"
                placeholder="DD/MM/AAAA"
                v-model.trim="fechaNacimiento"
                @input="darAutoFormatoFecha()"
              />
            </div>
          </div>

          <div class="mb-3">
            <label class="col-form-label pt-0" for="tarjeta"
              >Tarjeta de regalo*</label
            >
            <input
              class="form-control"
              id="tarjeta"
              type="number"
              aria-describedby="tarjeta"
              placeholder="Introduce tu tarjeta de regalo"
              v-model.trim="tarjeta"
            />
          </div>
          
        </div>
        <div class="card-footer text-end">
          <button
            :disabled="botonDesactivado"
            class="btn btn-secondary me-2"
            type="button"
            @click="cancelarVerificacion()"
          >
            Cancelar
          </button>
          <button
            class="btn btn-primary"
            :disabled="botonDesactivado"
            type="submit"
          >
            <div
              class="loader-box m-0"
              style="height: 20px"
              v-if="botonDesactivado"
            >
              <div
                class="loader-2 me-2"
                style="height: 25px !important; width: 25px !important"
              ></div>
              <span>guardando...</span>
            </div>
            <span v-else>Guardar tarjeta</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
